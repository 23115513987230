import { delete0, get, post } from '@/utils/request'

const examApi = {
  getSubject: '/api/content/exam/subject',
  getSubjectKV: '/api/content/exam/subject/kv',
  getQuestionType: '/api/content/exam/question/type',
  getQuestion: '/api/content/exam/question',
  addQuestion: '/api/content/exam/question',
  getPaper: '/api/content/exam/paper',
  getPaperKeyValue: '/api/content/exam/paper/kv',
  addPaper: '/api/content/exam/paper',
  startExam: '/api/content/exam/start',
  submitExam: '/api/content/exam/submit',
  getExamResult: '/api/content/exam/result/view',
  getExamMark: '/api/content/exam/result/mark',
  submitExamMark: '/api/content/exam/result/mark'
}

export function getSubject() {
  return get(examApi.getSubject)
}

export function getSubjectKV() {
  return get(examApi.getSubjectKV)
}

export function getQuestionType() {
  return get(examApi.getQuestionType)
}

export function getQuestions(param) {
  return get(examApi.getQuestion, param)
}

export function deleteQuestion(questionId) {
  return delete0(examApi.getQuestion + '/' + questionId)
}

export function getQuestion(questionId) {
  return get(examApi.getQuestion + '/' + questionId)
}

export function addQuestion(data) {
  return post(examApi.addQuestion, data)
}

export function getPapers(param) {
  return get(examApi.getPaper, param)
}

export function getPaper(paperId) {
  return get(examApi.getPaper + '/' + paperId)
}

export function getPaperKeyValues() {
  return get(examApi.getPaperKeyValue)
}

export function addPaper(data) {
  return post(examApi.addPaper, data)
}

export function deletePaper(paperId) {
  return delete0(examApi.addPaper + '/' + paperId)
}

export function getExamInfo(paperId) {
  return get(examApi.startExam + '/' + paperId)
}

export function getPaperQuestions(paperId) {
  return get('/api/content/exam/paper/' + paperId + '/question')
}

export function submitExam(data) {
  return post(examApi.submitExam, data)
}

export function getExamResult(examId) {
  return get(examApi.getExamResult + '/' + examId)
}

export function getExamMarkList(params) {
  return get(examApi.getExamMark, params)
}

export function submitExamMark(data) {
  return post(examApi.submitExamMark, data)
}

export function getExamScoreList() {
  return get('/api/content/exam/result/score')
}

export function getExamCount() {
  return get('/api/content/exam/statistic/count')
}

export function getExamPassRate() {
  return get('/api/content/exam/statistic/rate')
}
